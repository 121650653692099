@use "sass:map";
@use '@angular/material' as mat;
@import '@angular/material/theming';
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
//@include mat.legacy-typography-hierarchy(mat.define-typography-config());
//@include mat.all-legacy-component-typographies();
@include mat.core();

@import '/src/carina-palette.scss';

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$carina-typography: mat.define-typography-config(
  $font-family: 'Montserrat, sans-serif',
  $headline-5: mat.define-typography-level(20px, 22px, bold),
  $headline-6: mat.define-typography-level(18px, 22px, bold),
  $subtitle-1: mat.define-typography-level(18px, 22px, bold),
  $body-1: mat.define-typography-level(16px, 22px, normal),
  $body-2: mat.define-typography-level(14px, 16px, normal),
  $button: mat.define-typography-level(14px, 16px, bold),
  $caption: mat.define-typography-level(12px, 14px, bold), 
);

// Override typography for all Angular Material, including mat-base-typography and all components.
@include mat.all-component-typographies($carina-typography);

$carina-primary: mat.define-palette($carina-purple, 500, 100, 900);
$carina-accent: mat.define-palette($carina-green, 500, 100, 900);
$carina-warn: mat.define-palette(mat.$red-palette);

// create theme (use mat-dark-theme for themes with dark backgrounds)
$carina-theme: mat.define-light-theme((
  color : (
    primary: $carina-primary,
    accent: $carina-accent,
    warn: $carina-warn
  ),
  density:0
));

@import '/src/carina-constants.scss';

.carina-theme {
  // use our theme with angular-material-theme mixin
  @include mat.all-component-themes($carina-theme);  
}

.carina-theme .mat-mdc-raised-button {
  line-height: 32px;
}

.carina-theme h1 {
  //@include mat.typography-level($carina-typography, headline); 
  margin: 0;
  color: mat.get-color-from-palette($carina-primary, 500);
  padding: $default-gutter 0px;
  background-color: $grey;
}

.carina-theme h2 {
  margin: 0;
  color: black;
  padding-top: $default-gutter;
  margin-bottom:$default-gutter;
  border-bottom: $narrow-margin solid $border-grey;
}


html, body { height: 100%; }
body { margin: 0 }


.halfColumn {
  width:$two-column-width;
  min-width:$two-column-width;
}

.thirdColumn {
  width:$three-column-width;
}

section {
  margin: $default-gutter;
}

.carina-theme .mat-mdc-mini-fab {
  width: 28px;
  height: 28px;
}

.carina-theme .mat-mdc-mini-fab mat-icon {
  font-size: 18px;
  margin-top: -6px;
}

.carina-theme .carina-header-button {
  background-image: url(/assets/images/CarinaHeaderButtons.png);
  border-radius: 4px;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.carina-theme .mat-ripple.mat-button-ripple {
    border-radius: 4px;
}

#hdrbtnHome {
  background-position: -160px -40px; 
}

#hdrbtnConfigure {
  background-position: -320px 0px; 
}

#hdrbtnAssist {
  background-position: -200px -40px; 
}

#hdrbtnReset {
  background-position: -280px 0px; 
}

.carina-theme .mat-toolbar {
  background: white;
}

.carina-theme .ErrorDiv {
  background-color: #ff9999;
  color:black;
  font-weight:bold;
  padding: 10px 10px 0px 10px;
}

.carina-theme .anouncePanel {
  margin-top:30px;
  text-align: center;
}
.carina-theme .anouncePanel a {
text-decoration: none;
}

.carina-theme .versionPanel {
  margin-top:10px;
  text-align: center;
}

.carina-theme .mat-location {
  background-color: mat.get-color-from-palette($carina-green, 500);
}

.carina-theme .mat-library {
  background-color: mat.get-color-from-palette($carina-lilac, 500);
}

.carina-theme .mat-utility {
  background-color: mat.get-color-from-palette($carina-blue, 500);
}

.carina-theme .mat-report {
  background-color: mat.get-color-from-palette($carina-accent, 500);
}


$shade: 0;
@while $shade < 1000 {
  .carina-theme .mat-green-#{$shade} {
    background-color: mat.get-color-from-palette($carina-green, $shade);
  }

  .carina-theme .mat-lilac-#{$shade} {
    background-color: mat.get-color-from-palette($carina-lilac, $shade);
  }

  .carina-theme .mat-blue-#{$shade} {
    background-color: mat.get-color-from-palette($carina-blue, $shade);
  }

  .carina-theme .mat-yellow-#{$shade} {
    background-color: mat.get-color-from-palette($carina-yellow, $shade);
  }

  $shade: $shade + 100;
}

// Theme styles that only apply to the outline appearance of the form-field.


@mixin carina-outline-color($config-or-theme) {
  $config: mat.get-color-config($config-or-theme);
  $primary: map.get($config, primary);
  $accent: map.get($config, accent);

  $warn: map.get($config, warn);
  $foreground: map.get($config, foreground);
  $is-dark-theme: map.get($config, is-dark);

  $label-disabled-color: mat.get-color-from-palette($foreground, disabled-text);
  $outline-color:
    mat.get-color-from-palette($foreground, divider, if($is-dark-theme, 0.3, 0.12));
  $outline-color-hover:
    mat.get-color-from-palette($foreground, divider, if($is-dark-theme, 1, 0.87));
  $outline-color-primary: mat.get-color-from-palette($primary);
  $outline-color-accent: mat.get-color-from-palette($accent);
  $outline-color-warn: mat.get-color-from-palette($warn);
  $outline-color-valid: mat.get-color-from-palette($carina-green, 500);

  $outline-color-disabled:
    mat.get-color-from-palette($foreground, divider, if($is-dark-theme, 0.15, 0.06));

  .carina-theme {

    .mat-form-field-appearance-outline {
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-outline {
        color: $outline-color;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-outline-thick {
        color: $outline-color-hover;
      }

      &.mat-focused {
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
          //color: $outline-color-primary;
        color: $outline-color-valid;
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        &.carina-pristine {
          color: $outline-color-primary;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        &.mat-warn {
          color: $outline-color-warn;
        }
      }

      // Class repeated so that rule is specific enough to override focused accent color case.
      &.mat-form-field-invalid {
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        color: $outline-color-warn;
      }

      &.mat-form-field-disabled {
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-label {
          color: $label-disabled-color;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-outline {
          color: $outline-color-disabled;
        }
      }
    }
  }
}

@include carina-outline-color($carina-theme);

.carina-datepicker-month .mat-calendar-period-button {
  display:none;
}
