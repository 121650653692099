
$primary-colour : #7412FF;
$secondary-green : #0FDB99;
$secondary-yellow : #FFB000;
$grey: #ebebeb;
$dark-grey: #cbcbcb;
$border-grey: #aaaaaa;

$page-width : 1280px;
$two-column-width : 470px;
$three-column-width : 300px;
$wide-gutter : 15px;  
$widest-gutter : 20px;  
$default-gutter : 10px;  
$narrow-gutter : 5px;  
$default-margin : 5px; 
$narrow-margin : 2.5px; 

$field-width-standard: 220px;