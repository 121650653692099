@use '@angular/material' as mat;

.carina-theme .assistStep {
  margin-bottom: 10px;
  flex-flow: row wrap;
  display: flex;
  align-items : stretch;
}

.carina-theme .assistStep.assistRow {
  margin-bottom: 0px;
}

.carina-theme .stepView {
  margin-right:10px;
}

// .carina-theme .stepHeading {
//   flex: 19 0 0;
//   background-color: #e8c7ff;
//   font-weight: bold;
//   padding-left: 10px;
// }

// .carina-theme .stepSubHeading {
//   flex: 19 0 0;
//   background-color: #f1ddff;
//   font-weight: bold;
//   padding-left: 10px;
// }

.carina-theme .stepOffset {
  flex: 2 0 0;
  display: flex;
  align-items: center;
}

.carina-theme .stepIndentLeft, .carina-theme .stepIndentRight {
  flex: 0 0 0;
}

//.carina-theme .stepLevel {
//  flex: 1 0 0;
//}

.carina-theme .stepText {
  flex: 16 0 0;
  padding: 0;
  cursor: pointer;
  align-self: center;
  display:flex;
}

.carina-theme .stepButtons{
  flex: 4 0 0;
  display:flex;
  justify-content: flex-end;
} 

.carina-theme .stepDateRange {
  flex: 16 0 0;
  padding: 0;
  cursor: pointer;  
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;
}

.carina-theme .stepDateRange .dateLabel {
  margin: 0px 20px;
}



.carina-theme div[carina-indent="0"] {

  .stepOffset {
    flex: 2 0 0;
  }

  .stepIndentLeft, .stepIndentRight {
    flex: 0 0 0;
  }

  .stepLevel {
    flex: 0 0 0;
  }

  .stepHeading {
    flex: 22 0 0;
    background-color: #e8c7ff;
    font-weight: bold;
    padding-left: 10px;
  }  
  
  .stepText {
    flex: 16 0 0;
    padding: 0;
    cursor: pointer;
    align-self: center;
    display:flex;
  }

  .stepButtons{
    flex: 4 0 0;
    display:flex;
    justify-content: flex-end;
  } 
    
}

.carina-theme div[carina-indent="1"] {

  .stepOffset {
    flex: 2 0 0;
  }
  
  .stepIndentLeft {
    flex: .5 0 0;
    border-right: 3px solid #aaaaaa;
  }

  .stepIndentRight {
    flex: .5 0 0;
  }
      
  .stepLevel {
    flex: 1 0 0;
  }

  .stepHeading {
    flex: 21 0 0;
    background-color: #f1ddff;
    font-weight: bold;
    padding-left: 10px;
  }  

  .stepText {
    flex: 15 0 0;
    padding: 0;
    cursor: pointer;
    align-self: center;
    display:flex;  
  }
  
  .stepButtons{
    flex: 4 0 0;
    display:flex;
    justify-content: flex-end;
  } 
  
}

.carina-theme div[carina-indent="2"] {
      
  .stepLevel {
    flex: 2 0 0;
  }

  .stepHeading {
    flex: 20 0 0;
    background-color: #f1ddff;
    font-weight: bold;
    padding-left: 10px;
  }  
  
}

.carina-theme .stepText ul {
  padding-left: 20px;
}


.carina-theme .stepButtonEnd {
  display: flex;
  align-items: flex-end;
} 

.carina-theme .stepButtonStart {
  display: flex;
  align-items: flex-start;
} 

.carina-theme .stepShowIcon {
  flex: 4 0 0;
  display:flex;
  justify-content:center;
} 

.carina-theme .stepShowIconLeft {
  flex:3 0 0;
}

.carina-theme .stepShowIconRight {
  flex:1 0 0;
  align-self: center;
}

.carina-theme .stepButtonGroup {
  flex: 26 0 0 ;
  display: flex;
  justify-content: flex-end;
} 

.carina-theme .stepButtonGroupPad {
  flex: 1 0 0 ;
} 

.carina-theme .stepPrompt {
  font-style: italic;
  max-height: 872px;
}

.carina-theme .assistEntry {
  width:100%;
  padding:0;
}

/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.carina-theme .assistStep .mat-button-toggle-checked {
  background-color: mat.get-color-from-palette($carina-green, 500);
}

.carina-theme .assistStep .mat-grid-tile .mat-figure {
  justify-content: left;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.carina-theme .assistStep .mat-form-field-wrapper {
  margin:0;
  padding:0;
}


/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.carina-theme .assistStep .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height:32px;
}

.carina-theme .assistStep p {
  margin: 6px 0px;
}

.carina-theme p.stepSelected {
  margin: 6px 0px 6px 10px;
}

/*TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.carina-theme .assistStep .mat-button-toggle-standalone.mat-button-toggle-appearance-standard, .carina-theme .assistStep .mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(0, 0, 0, 0.50);
}

.carina-theme .assistStep .mat-mdc-outlined-button {
  border: solid 1px rgba(0, 0, 0, 0.50);
  margin-left: 10px;
}

.carina-theme .assistStep .mat-mdc-button {
  line-height: 32px;
  padding:0;
  min-width: 20px;
  border-radius: 0px;
}

.carina-theme .assistAction .actionLabel {
  font-weight: bold;
}

.carina-theme .assistAction .actionLabel, .carina-theme .assistAction .actionText {
  margin-right: 5px;
}

.carina-theme .actionItem {
  margin-bottom: 10px;
}

.carina-theme .assistAction {
  flex-flow: row wrap;
  display: flex;
  align-items : center; 
}
  
.carina-theme .actionSection,
.carina-theme .actionContent {
  margin: 5px 0;
  padding-left: 10px;
}

.carina-theme .actionSection {
  display: flex;
  align-items: center;
}

.carina-theme .actionContent {
  padding-left: 10px;
}

.carina-theme .actionContent span {
  white-space: pre-line;
}

.carina-theme .actionCheckbox {
  padding-right: 5px;
}

.carina-theme .actionDisabled span {
  text-decoration:line-through ;
  color:lightgray;
}

.carina-theme .actionProcessed {
  background: mat.get-color-from-palette($carina-green, 500);
}

.carina-theme .actionFailure {
  background: mat.get-color-from-palette($carina-accent, 500);
}

.carina-theme .actionSection.actionHighlight {
  border-left: #7412ff 2px solid;
  padding-left: 8px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.carina-theme .actionHeading {
  flex: 14 0 0;
}
.carina-theme .actionText {
  flex: 10 0 0;
  padding-right: 10px;
}
.carina-theme .actionButtons {
  flex: 4 0 0 ;
} 

.carina-theme .assistValue {
  margin-bottom: 5px;  
}

.carina-theme .valueText {
  margin: 5px 0;
  display: block;
}

.carina-theme .valueContent {
  display: block;
  white-space: pre-line;
}

.carina-theme .valueText.title {
  font-weight: bold;
}

.carina-theme .valueLabel {
  font-weight: bold;
  margin: 5px 5px 5px 0;
  min-width: 135px;
  display: inline-block;
}

carina-theme .valuePendingLarge {
  margin-top:5px;
  margin-left:5px;
}

.carina-theme .imgButton16 {
  background-image: url('/assets/images/CarinaButtons.png?4');
}

#flagGreenTick {
  background-position: -2px -2px; 
}

.carina-theme .elementHighlight {
  font-weight:bold;
  //background-color:#d5b8ff ;
}

.carina-theme .mat-mdc-header-row, .carina-theme .mat-mdc-row, .carina-theme .mat-mdc-footer-row {
  min-height:22px;
}

.carina-theme .mat-mdc-header-cell {
  font-weight:bold;
  color:black;
}

.carina-theme .stepIcon {
  background-image: url('/assets/images/CarinaButtons.png?4');
  width: 16px;
  height: 16px;
}

.carina-theme .stepIcon:active {
  background-color: lightgrey;
}


.iconGreenTick {
  background-position: -2px -2px; 
}

.iconGreyTick {
  background-position: -2px -62px; 
}

.iconGreyCross {
  background-position: -22px -62px; 
}

.iconRedCross {
  background-position: -22px -2px; 
}

.iconOrangeWarn {
  background-position: -62px -42px; 

}

.iconRightArrow {
  background-position: -42px -22px; 
}

.iconDownload {
  background-position: -82px -42px; 
}

.iconCopy {
  background-position: -2px -22px; 
}

.iconBlank {
  background-position: -102px -42px; 
}

.carina-theme mat-cell:first-of-type, .carina-theme mat-header-cell:first-of-type, .carina-theme mat-footer-cell:first-of-type {
  padding-left:10px;
}
.carina-theme mat-cell:last-of-type, .carina-theme mat-header-cell:last-of-type, .carina-theme mat-footer-cell:last-of-type {
  padding-right:10px;
}

.carina-theme .mat-expansion-panel-header {
  @include mat.typography-level($carina-typography, body-1); 
  padding: 0 24px 0 0;
  height: auto;
}

.carina-theme .mat-expansion-panel-body {
  padding: 0;
}

.carina-theme .mat-expansion-panel-header-title {
  flex-grow:unset;
}

.carina-theme .mat-accordion .mat-expansion-panel:last-of-type, 
.carina-theme .mat-accordion .mat-expansion-panel:first-of-type {
  border-radius: 0px;
}

.carina-theme .mat-expansion-panel-spacing {
  margin: 0;
}

.carina-theme .assistStepBuffer {
  min-height:200px;
}

.carina-theme .assistValueBuffer {
  min-height:800px;
}


@keyframes spinner {
  to {transform: rotate(360deg);}
}
 
.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner .8s linear infinite;
}

.carina-theme .logText {
  margin-top: 10px;
}

.carina-theme .consoleLog {
  margin-top: 10px;
  width: 100%;
  height: 600px;
  overflow-y: scroll;
  white-space: pre-wrap;
}

.carina-theme .valuePanelTitle {
  padding: 5px 0px 5px 3px
}

.carina-theme .valuePanelContent {
  padding: 3px 0px 5px 10px
}

.carina-theme .valueChipList {
  padding-left: 5px;
}

.carina-theme .valueChip {
  @include mat.typography-level($carina-typography, body-1);
  border-radius: 12px;
  min-height: 24px;
  margin: 2px !important;
}

.carina-theme .valueChipSet {
  background: mat.get-color-from-palette($carina-accent, 500) !important;
  font-weight: bold;
}

.carina-theme .valueTableCell {
  display:flex;
}

/*TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
.carina-theme .mat-chip-list-wrapper {
  margin: 0px;
}

.carina-theme .msgBoxText {
  min-height: 6px;
  margin-bottom: 6px;

}

.carina-theme .stepFlags {
  padding:0 10px;
  flex: 20 0 0 ;
  flex-flow: row wrap;
  display:flex;
}

.carina-theme .stepFlag {
  margin-top: 5px;
  flex: 1 1 50%;
  display:flex;
  align-items: center;
}

.carina-theme .stepFlagWrap {
  padding-bottom:1px;
}

.carina-theme .imgMcFlag {
  background-image: url('/assets/images/mcIcons.jpg?4');
  height:12px;
  width:12px;
  margin: 0 10px -1px 10px;
}

#mc_bluedot {   // Priority paper
  background-position: 0px 0px;
}
#mc_reddot {   // Complicated
  background-position: -132px 0px;
}
#mc_greendot {   // Appeal
  background-position: -12px 0px;
}
#mc_purpledot {   // Urgent
  background-position: -144px 0px;
}
#mc_pinkx {   // Second opinion please
  background-position: -24px 0px;
}
#mc_greensquare {   // Pending
  background-position: -156px 0px;
}
#mc_greentriangledown {   // C/R -> editorial
  background-position: -36px 0px;
}
#mc_orangetriangleup {   // C/R -> admin
  background-position: -168px 0px;
}
#mc_yellowdiamond {   // FAO Editor
  background-position: -48px 0px;
}
#mc_graycheck {   // AM opt-out
  background-position: -180px 0px;
}
#mc_YellowStar {   // Transformative Agreement
  background-position: -60px 0px;
}
#mc_LightBlueStar {   // Promotion worthy
  background-position: -192px 0px;
}
#mc_OrangeSquare {   // Honorarium due
  background-position: -72px 0px;
}
#mc_RedSquare {   // Don''t touch
  background-position: -204px 0px;
}
#mc_LightGreenSquare {   // Journal-specific flag
  background-position: -84px 0px;
}
#mc_green_box_black_bar {   // [blank 1]
  background-position: -216px 0px;
}
#mc_Green_box_black_vertical_bar {   // Journal-specific flag
  background-position: -96px 0px;
}
#mc_green_box_black_X {   // Withdrawn: APC loss
  background-position: -228px 0px;
}
#mc_red_box_black_bar {   // Journal-specific flag
  background-position: -108px 0px;
}
#mc_red_box_black_vertical_bar {   // [blank 2]
  background-position: -240px 0px;
}
#mc_red_box_black_X {   // Ethical cases
  background-position: -120px 0px;
}
#mc_star_of_life {   // Training
  background-position: -252px 0px;
}

.carina-theme .assistStatement {
  display: inline;
}

.carina-theme mat-hint.mat-mdc-form-field-hint {
  color: mat.get-color-from-palette($carina-red, 500);
}

.carina-theme .actionWrapper {
  flex:1;
  display:flex;
  flex-direction: column;
}

.carina-theme .actionMain {
  flex:1;
  display:flex;
  flex-direction: row;
}

.carina-theme .actionStatement {
  flex:9;
}

.carina-theme .actionSub {
  flex:1;
  display:flex;
  flex-direction: column;
}

.carina-theme .carinaActionButton {
  margin: 0 10px 10px 0;
}
